import fetchScheduledClasses from 'apis/getScheduledClasses'
import { useEffect, useState } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import useAuthStore from 'stores/auth'
import { ClassProduct, ScheduledClass as SClass, ScheduledClass } from 'types/shopScheduleTypes'
import { getProductsQueryBySkuArray } from 'graphql/queries/products'
import useHerHubStore from 'stores/herHub'
import { addTimeZoneTimeString, buildFullDateTimeString } from 'utils/time'
import { removeDuplicates, sortClasses } from 'utils/scheduledClasses'
import useApolloClient from 'apollo/apolloClient'
import usePatientDetails from './usePatientDetails'
import useScheduledStore from 'stores/scheduledClasses'

const DEFAULT_ERROR_MESSAGE = `Oops... We encountered an error getting the available classes for you.`

export type useClassReturn = {
  scheduledClasses: Array<SClass>;
  completedClasses: Array<SClass>;
  classProducts: Array<ClassProduct>;
  loading: boolean;
  error: string;
  noClasses: boolean;
  nextClass: SClass | null;
}

const useScheduledClasses = (): useClassReturn => {
  const [ completedClasses, setCompletedClasses ] = useState<SClass[]>( [] )
  const [ classProducts, setClassProducts ] = useState<ClassProduct[]>( [] )

  const [ error, setError ] = useState<string>( `` )
  const [ loading, setLoading ] = useState( true )
  const { buildAuthorizer } = useAuthStore()
  const { timezone } = usePatientDetails()
  const {
    recentCancel,
    wasRecentlyCanceled,
    removeUpdatedReschedules,
    removeUpdatedCancels,
    recentReschedule
  } = useHerHubStore()
  const { scheduledClasses, setScheduledClasses } = useScheduledStore()
  const apolloClient = useApolloClient()

  useEffect( () => {
    if ( timezone ) {
      fetchScheduledClasses( buildAuthorizer(), timezone ).then( ( response ) => {
        if ( response?.errors?.length || response?.meta?.status !== `OK` ) return
        const upcomingClasses = response?.data?.upcoming
        const updatedRecents = removeUpdatedReschedules( upcomingClasses )
        removeUpdatedCancels( upcomingClasses )
        const scheduled = sortClasses( removeDuplicates( serializeClasses( [ ...updatedRecents, ...upcomingClasses ] ) ) ) ?? []
        const completed = sortClasses( response?.data?.completed ) ?? []

        fetchClassProducts( [ ...scheduled, ...completed ] )
        setCompletedClasses( [ ...completed ] )
        setScheduledClasses( [ ...scheduled ] )
        setLoading( false )
      })
        .catch( ( error: Error ) => {
          reportToSentry( new Error( `Her Hub: Error getting classes`, {
            cause: error
          }), {
            authorizationHeader: buildAuthorizer()
          })
          setError( DEFAULT_ERROR_MESSAGE )
        })
    }
  }, [ timezone ] )

  useEffect( () => {
    let zoomInterval: NodeJS.Timeout
    // poll until we are getting data from internal
    // if there is a class without a zoom link we need to setup polling to poll for zoom links so we can enable add to calendar once it is ready
    if ( ( recentReschedule?.length || ( scheduledClasses?.length && scheduledClasses.some( ( scheduledClass: ScheduledClass ) => !scheduledClass.zoom_link ) ) ) && timezone ) {
      zoomInterval = setInterval( ( timezone ) => {
        fetchScheduledClasses( buildAuthorizer(), timezone ).then( ( response ) => {

          if ( response?.errors?.length || response?.meta?.status !== `OK` ) return
          const upcomingClasses = response?.data?.upcoming
          const updatedRecents = removeUpdatedReschedules( upcomingClasses )
          removeUpdatedCancels( upcomingClasses )
          const scheduled = sortClasses( removeDuplicates( serializeClasses( [ ...updatedRecents, ...upcomingClasses ] ) ) ) ?? []

          const updateScheduledClasses = scheduled
          const classesMissingLink = scheduled.filter( ( scheduledClass: ScheduledClass ) => !scheduledClass.zoom_link )

          classesMissingLink.forEach( ( scheduledClass: ScheduledClass ) => {
            // get the index we need to update if the class now has a zoom link
            const classMissingLink = updateScheduledClasses.findIndex( ( _class: ScheduledClass ) => _class.class_id === scheduledClass.class_id )
            // get the latest class that was fetched to check for zoom link
            const latestClassMissingLink = scheduled.find( ( _class: ScheduledClass ) => _class.class_pk === scheduledClass.class_pk )

            if ( latestClassMissingLink?.zoom_link ) {
              updateScheduledClasses[classMissingLink].zoom_link = latestClassMissingLink.zoom_link
            }
          })

          if ( JSON.stringify( scheduledClasses ) !== JSON.stringify( updateScheduledClasses ) ) {
            setScheduledClasses( [ ...updateScheduledClasses ] )
          }
          setLoading( false )
        })
      }, 20000, timezone )
    }

    return () => clearInterval( zoomInterval )
  }, [ scheduledClasses, recentReschedule ] )

  useEffect( () => {
    // this is to update the next class block
    if ( scheduledClasses?.length ) {
      setScheduledClasses(
        scheduledClasses.filter( ( c: ScheduledClass ) => {
          return !wasRecentlyCanceled( c.class_pk )
        })
      )
    }
  }, [ recentCancel ] )

  async function fetchClassProducts( classes: Array<SClass> ) {

    const classProductsResult = await apolloClient.query({
      query: getProductsQueryBySkuArray,
      fetchPolicy: `network-only`,
      variables: {
        skus: classes.filter( c => c?.class_sku ).map( ( c ) => c.class_sku )
      }
    })
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Shop and Schedule: Product query error`, {
          cause: error
        }), {
          classSkusArray: classes.map( ( c ) => c.class_sku )
        })

        return setError( DEFAULT_ERROR_MESSAGE )
      })

    const items = classProductsResult?.data?.resupplyProducts?.items
    setClassProducts( items ? [ ...items ] : [] )
  }

  function serializeClasses( _classes: SClass[] ) {
    return _classes.filter( ( _class => { // Remove classes that have been recently canceled
      return !wasRecentlyCanceled( _class.class_pk )
    }) )
      .map( ( _c ) => { // Add full scheduled date (day and time)
        return {
          ..._c,
          scheduled_date: addTimeZoneTimeString( buildFullDateTimeString( _c.scheduled_date as string, _c.begin_time ) )
        }
      })
      .filter( ( _class ) => { // Remove classes that have already happened
        return new Date( _class.scheduled_date ).getTime() + ( _class.duration_minutes * 60 * 1000 ) > new Date().getTime()
      })
  }

  return {
    scheduledClasses,
    completedClasses,
    classProducts,
    loading,
    error,
    noClasses: !scheduledClasses.length && !completedClasses.length && !loading && !error,
    nextClass: scheduledClasses.length ? scheduledClasses[0] : null
  }
}

export default useScheduledClasses