import { usaStates } from 'components/Forms/constants'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/isoWeek'

import { TIME_ZONES } from 'utils/constants'
import { getCurrentTimeZoneString } from './time'

dayjs.extend( weekday )

export const addZeroIfNeeded = day => { return ( day < 10 ? `0${day}` : day ) }

export function getCurrentTimeZone() {
  return (
    TIME_ZONES.find(
      ({ name }) => { return name === Intl.DateTimeFormat().resolvedOptions().timeZone }
    ) || TIME_ZONES[3] // EST
  )
}

// from date string to date obj
export function getMDY( date, format = `YYYY-MM-DD` ) {
  if ( !dayjs( date, format ).isValid() ) return {}
  const dateObj = format ? dayjs( date, format ) : dayjs( date )

  return {
    month: dateObj.get( `month` ),
    date: dateObj.get( `date` ),
    year: dateObj.get( `year` ),
    weekday: dateObj.isoWeekday()
  }
}

export const normalizeDateForSubmission = date => {
  const tempDate = new Date( date )
  const year = tempDate.getUTCFullYear()
  const month = tempDate.getUTCMonth() + 1
  const day = tempDate.getUTCDate()

  return `${year}-${month}-${day}`
}

export const formatPhoneNumber = value => {
  // strip all non-numeric characters, and removal all non-digits
  let _val = value.replace( /[- )(]/g, `` ).replace( /\D/g, `` )

  if ( _val.length === 1 && _val === `1` ){
    return ``
  }

  const a = _val.length > 3 ? `(${_val.substring( 0, 3 )})` : _val
  const b = _val.substring( 3, 6 ) ? ` ${_val.substring( 3, 6 )}` : ``
  const c = _val.substring( 6 ) ? `-${_val.substring( 6 )}` : ``

  // pattern `(888) 555-1212`
  return `${a}${b}${c}`.slice( 0, 14 )
}

export function getActiveMonthOffset( month ){
  if ( !month ) return null

  return 1 - month

}

export const getCurrentMonthIndex = () => {
  const { month } = getMDY( new Date() )

  return month
}

const getObjectActiveMonthFactoringInYearChange = ( initialMonth, stepIterator ) => {
  const monthKeyUnmodified = initialMonth + stepIterator

  if ( monthKeyUnmodified >= 12 ){
    return ( monthKeyUnmodified - 12 ).toString()
  }

  return ( monthKeyUnmodified ).toString()
}


export const getActiveMonthToPaginatedMonthKey = ( initialMonth ) => {

  let newObj = {}

  // allowed pages
  const newArray = [ 1, 2, 3, 4, 5, 6 ]

  newArray.map( ( n, i ) => {
    const monthKey = getObjectActiveMonthFactoringInYearChange( initialMonth, i )
    newObj[monthKey] = n

  })

  return newObj
}

export const getHash = () => {
  const params = new URLSearchParams( window.location.search )
  const hash = params.get( `sgh` ) || params.get( `txt` )

  return hash ?? ``
}

export const scrollToTop = () => {
  // This message is used by Adobe Commerce to scroll to the top of the iframe in the embedded scheduler
  if ( window.location !== window?.parent?.location ) window?.parent?.postMessage( `scrollToTop`, `*` )
  window.scrollTo({
    top: 0,
    behavior: `smooth`
  })
}

// takes in a string and determines if it's an email or a phone number
export const isEmailOrPhone = ( value ) => {
  if ( isEmailValid( value ) ) return `email`
  if ( isPhoneValid( value ) ) return `phone`
}

export const isEmailValid = ( value ) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i

  return emailRegex.test( value )
}

export const isPhoneValid = ( value ) => {
  // 10 digit phone number regex that doesn't allow for the first digit to start with 1
  const phoneRegex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/

  return phoneRegex.test( value )
}

export const getStateCode = ( stateName ) => {
  const stateObj = usaStates.find( state => {
    if ( state.text === stateName ) return state
  })

  return stateObj?.value
}

function formatAMPM( date ) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? `pm` : `am`
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? `0`+minutes : minutes
  let strTime = hours + `:` + minutes + ` ` + ampm

  return strTime
}

export const getCalendarEventDescription = ( scheduledClass ) => {
  const hash = getHash()

  return `
  ${scheduledClass.class_title} with ${scheduledClass?.lc_name}

  ${scheduledClass.scheduled_date_pretty} at ${formatAMPM( new Date( scheduledClass.start_time_utc ) )} ${getCurrentTimeZoneString()}

  Location
  Zoom: ${scheduledClass.zoom_link}

  *Log into your Zoom account prior to clicking the meeting link

  To reschedule or cancel your class, click here: https://lactation.aeroflowbreastpumps.com/my-account${hash ? `?sgh=${hash}` : ``}
  `
}

export const getAppointmentCalendarEventDescription = ( appointment ) => {
  const hash = getHash()

  return `
  Lactation Visit with ${appointment.staff_display_name}

  ${appointment.scheduled_date_pretty} at ${formatAMPM( new Date( appointment.start_time ) )} ${getCurrentTimeZoneString()}

  Location
  Zoom: ${appointment.interaction_details}

  *Log into your Zoom account prior to clicking the meeting link

  To make changes to your appointment, click here: https://lactation.aeroflowbreastpumps.com/my-account${hash ? `?sgh=${hash}` : ``}
  
  Appointment cancellations or changes must be made at least 24 hours prior to your appointment time. Failure to attend or reschedule with at least 24 hour notice  will result in a $25 fee. If you arrive 15 minutes past the start of your meeting, your appointment will be cancelled and a $25 rescheduling fee will apply
  `
}