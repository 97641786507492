import { useEffect, useState } from 'react'
import useAuthStore from 'stores/auth'
import fetchZoomLinkTracking from 'apis/postZoomLinkTracking'
import { reportToSentry } from 'utils/reportToSentry'

export default function JoinNowHeader({ time, zoom_link, isAppointment, event_instance_id, setHideActions }: {
  time: string | Date,
  zoom_link: string,
  isAppointment?: boolean,
  event_instance_id: string,
  setHideActions?: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element | null {

  const { buildAuthorizer } = useAuthStore()
  const [ countdown, setCountdown ] = useState<string | null>( `0:00` )
  const [ sessionStatus, setSessionStatus ] = useState( `Your next class is starting soon!` )

  useEffect( () => {
    const interval = setInterval( () => {
      const now = new Date().getTime()
      const then = new Date( time ).getTime()
      const difference = then - now

      const hours = Math.floor( ( difference / 1000 / 60 / 60 ) )
      const seconds = Math.floor( ( difference / 1000 ) % 60 )
      const minutes = Math.floor( ( difference / 1000 / 60 ) % 60 )

      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

      const formattedCountdown = ( minutes < 0 || seconds < 0 ) ? `0:00` : `${formattedMinutes}:${formattedSeconds}`

      // hide cta links for appointments if the appointment starts in 24 hours and for classes if the class has already started
      if ( setHideActions ) setHideActions( isAppointment ? hours <= 24 : formattedCountdown === `0:00` )

      setCountdown( formattedCountdown )
      setSessionStatus( ( then <= now ) ? `Your ${isAppointment ? `appointment` : `class`} is in session` : `Your ${isAppointment ? `appointment` : `next class`} is starting soon!` )
    }, 1000 )

    return () => clearInterval( interval )
  }, [ countdown, time ] )

  const handleLaunchZoom = () => {
    if ( event_instance_id ) {
      fetchZoomLinkTracking( buildAuthorizer(), zoom_link, event_instance_id ).catch( ( e ) => {
        reportToSentry( e, {
          zoom_link,
          event_instance_id
        })
      })
    }
    window.open( zoom_link, `_blank` )
  }

  if ( !countdown ) return null

  return (
    <div>
      <p className="uppercase mb-2">{sessionStatus}</p>
      <div className="flex items-center justify-center gap-2">
        {/* Hiding the countdown for now and just displaying the join now link */}
        {/* <p className="hub-clock">{countdown}</p> */}
        {
          zoom_link &&
          <button
            className="primary-button"
            onClick={handleLaunchZoom}
          >
            {`Join Now`}
          </button>
        }
      </div>
    </div>
  )
}