import { useMemo } from 'react'
import { ClassDescription, ScheduledClass, TimeSlot } from 'types/shopScheduleTypes'
import useSeriesReference from 'hooks/useSeriesReference'
import useEligibleAppointments from 'hooks/useEligibleAppointments'

function useTimeSlotValues(
  classDetails: ClassDescription | undefined,
  scheduledClass: ScheduledClass | undefined,
  selectedTime: Date | null,
  selectedDay: Date | null,
  setSelectedDay: React.Dispatch<React.SetStateAction<Date | null>>,
  setSelectedTime: React.Dispatch<React.SetStateAction<Date | null>>,
  timezone: string | null
): { timeSlots: TimeSlot[]; timeSlotDates: Date[] } {

  const { seriesReference } = useSeriesReference()
  const { excludeDays } = useEligibleAppointments( timezone )

  const timeSlotDates = useMemo( () => {
    return classDetails?.timeslots?.filter( ( ( ts: TimeSlot ) => {
      // filter out days that already have a 1:1 or class scheduled
      if ( excludeDays && ( excludeDays as Set<string> ).has( ts.scheduled_date.substring( 0, 10 ) ) ) return false
      // filter out time slots that are before the selected time of the previous class
      const curSeriesRef = scheduledClass?.class_series_pk ? seriesReference.get( scheduledClass.class_series_pk ) : null
      if ( curSeriesRef && scheduledClass && curSeriesRef?.has( scheduledClass.class_sequence - 1 ) && ts.timeslot_exact_timestamp.localeCompare( curSeriesRef.get( scheduledClass.class_sequence - 1 ) ?? `` ) < 0 ) return false
      // filter out time slots that the same day as the previous class
      if ( curSeriesRef && scheduledClass && curSeriesRef?.has( scheduledClass.class_sequence - 1 ) && ( new Date( ts.timeslot_exact_timestamp ).toDateString() === new Date( curSeriesRef.get( scheduledClass.class_sequence - 1 ) || `` ).toDateString() ) ) return false

      // Filter out already scheduled date
      return scheduledClass && new Date( ts.timeslot_exact_timestamp ).toDateString() !== new Date( scheduledClass.timeslot_exact_timestamp || `` ).toDateString()
    }) ).map( ( ts: TimeSlot, i: number ) => { // Format timeSlots as Dates
      if ( i === 0 && !selectedDay ) {
        setSelectedDay( new Date( ts.timeslot_exact_timestamp ) ) // auto select first day
        if ( !selectedTime ) setSelectedTime( new Date( ts.timeslot_exact_timestamp ) ) // auto select first time
      }

      return new Date( ts.timeslot_exact_timestamp )
    })
      .sort( ( a: Date, b: Date ) => a.getTime() - b.getTime() ) ?? [] // Sort Dates in order
  }, [ classDetails, excludeDays ] )

  const timeSlots = useMemo( () => {
    return classDetails?.timeslots?.filter( ( ts: TimeSlot ) => {
      // filter out time slots that are before the selected time of the previous class
      const curSeriesRef = scheduledClass?.class_series_pk ? seriesReference.get( scheduledClass.class_series_pk ) : null
      if ( curSeriesRef && scheduledClass && curSeriesRef?.has( scheduledClass.class_sequence - 1 ) && ts.timeslot_exact_timestamp.localeCompare( curSeriesRef.get( scheduledClass.class_sequence - 1 ) ?? `` ) < 0 ) return false

      const isSameDay = new Date( ts.timeslot_exact_timestamp ).toDateString() === selectedDay?.toDateString()
      if ( isSameDay ) return ts
    }) ?? []
  }, [ selectedDay ] )

  return {
    timeSlots,
    timeSlotDates
  }
}

export default useTimeSlotValues